:root {
  --blue: #6495ed;
  --white: #faf0e6;
  --red: #dc3545;
  --green: #198754;
}

.terms-condition-textbox {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  margin: auto;
}

.agree-btn {
  background-color: white !important;
  border: var(--green) 2px solid !important;
  color: var(--green) !important;
}
.agree-btn:hover {
  background-color: var(--green) !important;
  color: white !important;
}
.decline-btn {
  background-color: white !important;
  border: var(--red) 2px solid !important;
  color: var(--red) !important;
}
.decline-btn:hover {
  background-color: var(--red) !important;
  color: white !important;
}

/* width */
.terms-condition-textbox::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.terms-condition-textbox::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.terms-condition-textbox::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}

/* Handle on hover */
.terms-condition-textbox::-webkit-scrollbar-thumb:hover {
  background: #6b6b6b;
}
