.align-me {
  top: 30px;
}

.input-order {
  position: relative;
  width: 122px;
  height: 28px;
  border: 0.6px solid #d5d5d5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}


.custom-input {
  width: 100%;
  height: 100%;
  border: none; 
  background-color: transparent;
  font-size: 14px;
  outline: none;
  /* padding-right: 20px; */
}


.custom-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #2b303466;
  pointer-events: none;
}
