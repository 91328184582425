/* #showcase-area {
  min-height: 80vh;
  background: url("https://res.cloudinary.com/dxqfv1xa2/image/upload/v1695505117/ublrgxs68xmpkv9pcc62.jpg")
    center no-repeat;
} */

/* .dark-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 80vh;
}

#why-orange {
  background: rgb(247, 132, 49);
  background: linear-gradient(
    90deg,
    rgba(247, 132, 49, 1) 0%,
    rgba(2, 0, 36, 1) 100%
  );
} */

.storageunitcard{
  background-color: white;
}


.storageunitcard:hover {
  background-color: #FFCC00;
}


.circle {
  width: 9em;
  height: 9em;
  border-radius: 50%;
  background-color: #FFCC00;
  border: 7px solid #fff;
  box-shadow: 0 0 0 3px #FFCC00;
  display: flex;          
  justify-content: center;
  align-items: center;    
  overflow: hidden;       
}

.separator {
  width: 200px;                
  height: 6px;              
  background-color: #FFCC00;
  border-radius: 8px;
  /* margin-bottom: 55%; */
}
.custom-separator-margin {
  margin-bottom: 2rem; 
}

@media (min-width: 768px) {
  .custom-separator-margin {
    margin-bottom: 9rem; 
}
}

@media (min-width: 992px) {
  .custom-separator-margin {
    margin-bottom: 9rem; 
  }
}



.sj-mainh{
  color: #5E5E5E;
  font-weight: 300;
  font-size: '24px';
  line-height: 1;
}

.sj-interp{

  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.sj-desc{
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;

}

.customp-padding {
  padding-left: 4.5rem;
  padding-right: 4.3rem;
}

.sj-heading{ 
  font-size: 52px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.sj-heading-main{
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}

.outer-container {
  height: '120%';
  width: '100%';
  display: flex;
  justify-content: space-between;
}

.flex-fill {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
    .flex-fill {
      flex-basis: 50%;
      max-width: 50%;
    }
}

.inner-div {
  background-color: #FFDF61;
}

.picture-div {
  width: 350px;
  height: 450px;
  margin-left: 20px;
  position: relative;
  overflow: hidden;
}


.not-activebtn{
  background-color: #ECEDF1;
  /* border: 2px solid #FFCC00; */
  color: #8E8E9E;
  border: none;
  border-radius: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 42px;
  padding-right: 42px;
}
.activebtn{
  background-color: #FFCC00;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  border:none;
}

.inner-span-label{
  font-size: 32px;
  font-weight: 600;
}
.gap-7 {
  gap: 4rem; /* Adjust to your preferred spacing, e.g., 5rem = 80px */
}

.range-btn{
  border-radius: 14px;
  border: 1px;
  background-color: #FFCC00;
  color: #212529;

  display: flex;               
  justify-content: center;     
  align-items: center;         
  font-size: 20px;             
  font-weight: 500;
  text-align: center;

  width: fit-content;
  padding-left:19px;
  padding-right: 19px;
  padding-top: 4px;
  padding-bottom: 5px;
}

/* .unit-headings{
  font-size: 25px;  
  font-weight: 600;
  letter-spacing: -0.5px;
}
.unit-p{
  font-size: 18px;  
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 1.2;
} */


.unit-headings {
  font-size: 26px;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: #000034;
}


.unit-p {
  font-size: 18px; 
  font-weight: 400;
  letter-spacing: -0.5px;
  color: #000034;
}


@media (min-width: 768px) {
  .unit-headings {
    font-size: 25px;  
  font-weight: 600;
  letter-spacing: -0.5px;
  }

  .unit-p {
    font-size: 18px;  
  font-weight: 400;
  letter-spacing: -0.5px;
  }
}

/* .unit-pic{
  border-radius: 20px;
} */

.col-setting {
  background-color: #FFDF61; /* Change the background color to your desired color */
}

/* Adjust the image styles */
.img-landing {
  object-fit: cover;
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translateX(-50%);
  width: 175%;
  height: 120%;
  object-position: center;
  border-radius: 10px;
}

.footer-list{
  color: #343434;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.innerdivlabel{
  width: 36px;
  height: 36px;
  display: flex;               
  justify-content: center;     
  align-items: center;         
  border: 3px solid #FFCC00;
  border-radius: 8px;
  font-size: 20px;             
  font-weight: 500;
  text-align: center;
}
.innerdivlabel-not{
  width: 36px;
  height: 36px;
  display: flex;               
  justify-content: center;     
  align-items: center;         
  border: 3px solid #8E8EA0;
  border-radius: 8px;
  font-size: 20px;             
  font-weight: 500;
  text-align: center;
}


/* /////some home card adjustment */
@media (max-width: 767.98px) {
  .hidden-sm {
    display: none !important;
  }
}




.ddflex {
  width: 80%;
}


@media (min-width: 768px) {
  .ddflex {
    width: 101%;
  }
}


/* md-buttonset */

/* learrning */

.bg-gray {
  background-color: #F1F4F9 !important; /* Use !important if necessary */
  /* border-radius: 12px !important;  */
}
thead.table-active{
  background-color: #F1F4F9 !important;
  border-radius: 12px !important;
}

.order-table-content{
  color: #000034;
  font-size: 14px;
  font-weight: 600;
}

.order-table-bcontent{
  color: #202224;
  font-size: 14px;
  font-weight: 600;
}

.table {
  font-family: 'Nunito Sans', sans-serif !important;
}
