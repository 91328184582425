.cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  overflow: hidden;
  z-index: 100000;
}

.cookie-icon {
  font-size: 24px;
}

.cookie-text {
  flex-grow: 1;
  padding: 0 10px;
}

.cookie-buttons {
  display: flex;
  gap: 10px;
}

.cookie-button {
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.accept-button {
  background-color: #007bff;
  color: #fff;
}

.decline-button {
  background-color: #dc3545;
  color: #fff;
}

/* Media Query for Responsive Styling */
@media (max-width: 768px) {
  .cookie-notice {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
  }

  .cookie-text {
    padding: 10px 0;
  }

  .cookie-buttons {
    flex-direction: column;
  }

  .cookie-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
.cookie-notice {
  /* ... Your existing styles ... */
  transform: translateY(100%); /* Initially, hide the notice off-screen */
  transition: transform 0.5s ease; /* Add a transition for smooth animation */
}
.cookie-notice.visible {
  transform: translateY(
    0
  ); /* Show the notice by moving it to its original position */
}
