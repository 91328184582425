.promotion-banner {
  /* background-color: red; */
  background-color: white;
  border: 2px solid red;
  color: red;
  border-right: 0px solid white;
  /* color: white; */
  /* border-radius: 20px; */
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.order-cards{
  border: 2px solid #FFCC00;
  background-color: white;
  border-radius: 20px;
}

.order-cards-head{

  font-size: 32px;
  font-weight: 700;
  color:#202224;
}


.order-cards-p{
  font-size: 16px;
  font-weight: 500;
  color:#202224;
}


.units-info-p{
  font-size: 18px;
  font-weight: 600;
  color:#202224;
}

.some-text-p7{
  font-size: 16px;
  font-weight: 600;
  color:#202224;
}

.heading-image-g {
  background: linear-gradient(
    270deg,
    rgba(255, 204, 0, 0) 0%,
    rgba(255, 204, 0, 0.0921287) 34.9%,
    rgba(255, 204, 0, 0.32071) 58.06%,
    rgba(255, 204, 0, 0.868613) 78.39%,
    #FFCC00 100%
  );
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.unit-select{
    position: relative;
    width: 140px;
    height: 28px;
    border: 0.6px solid #d5d5d5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
}