/* Updated CSS to match desired navbar style */
/* .nav {
  background-color: #ffcc00;
  and #F5F6FA
} */

.navbar-nav .nav-link {
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px; /* Horizontal margin for spacing */
}

.navbar-nav .nav-item .nav-link:hover {
  color: #f78431; /* Highlight color on hover (matches theme) */
}

.find-storage-btn {
  background-color: #f78431;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.find-storage-btn:hover {
  background-color: #e07320;
}

.user-icon {
  margin-left: 15px;
  font-size: 27px;
  color: white;
}

.dropdown-menu {
  background-color: white;
  border-radius: 5px;
  padding: 0.5rem 0;
}

.dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  color: #333;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
  color: black;
}
