.marginTop-10 {
  margin-top: "10px";
}

.bgGray {
  background-color: #dae0e4;
}
.px-3 {
  padding-left: 20px !important;
  padding-right: 10px !important;
}

.py-2 {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
}

.btn-custom {
  border-radius: 15px; /* Adjust for rounded corners */
  background-color: orange; /* Default button color */
  color: black; /* Text color */
  border: 1px solid transparent; /* Remove default border */
}

.btn-custom:hover {
  background-color: darkorange; /* Color on hover */
}

.btn-custom:active {
  background-color: white; /* Color when clicked */
  color: orange; /* Text color when clicked */
  border: 1px solid orange; /* Border color when clicked */
}
/* .card-icon {
  font-size: 3rem;
  color: orange; 
}

.card {
  border-radius: 15px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.btn-custom {
  border-radius: 20px; /* Rounded corners for the button */
}

.btn-custom:active {
  background-color: white; /* Background color when clicked */
  color: orange; /* Text color when clicked */
  border-color: orange; /* Border color when clicked */
}

/* SizingGuidPage.css */
.fade-in {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.fade-in.active {
  opacity: 1;
}

.slide-in-left {
  transform: translateX(-100%);
  transition: transform 2s ease-in-out;
}

.slide-in-left.active {
  transform: translateX(0);
}

/* .zoom-in {
transform: scale(0);
transition: transform 1s ease-in-out;
}

.zoom-in.active {
transform: scale(1);
} */

.image-container {
  background-color: #000034; /* Dark grey background */
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-container img {
  max-width: 100%;
  height: auto;
}

.remainder {
  background-color: lightgray;
  padding: 25px;
  max-width: 73%;
  margin: 0 auto; /* Center align horizontally */
  text-align: justify; /* Justify text */
}

/* .accordion-button.collapsed { */
/* background: radial-gradient(circle, #ff8a00, #e52e71);  */
/* background: linear-gradient(to left,white, gray);
  background-color: gray;
  color:white; */
/* } */

.size-guide-dropdown {
  position: fixed; /* Use fixed positioning to keep it centered on the screen */
  top: 45.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 800px;
  background-color: white;
  padding: 3px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
  max-height: 80vh;
}

.size-guide-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .sizing-table th, .sizing-table td {
  border: none;
} */

.sizing-table {
  border: 1px solid gray;
  border-radius: 4px;
  overflow: hidden;
}
