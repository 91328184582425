.notification-container {
  position: relative;
}

.notification-icon-btn {
  background-color: transparent !important;
  border: none !important;                 
  box-shadow: none !important;             
  padding: 0;                              
}
/* 

.notification-icon {
  display: flex;
  align-items: center;
  position: relative;
} */

.notification-badge {
  position: absolute;
  top: -3px;
  right: 24px;
  background-color: #F93C65;
  color: white;
  border-radius: 50%;
  padding: 3px 5px;
  font-size: 13px;
  font-weight: 700;
}

.notification-dropdown-menu {
  min-width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: #fff;
  max-height: 70vh;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.notification-item {
  display: flex !important;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #f0f0f0;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.notification-details {
  display: flex;
  flex-direction: column;
}

.notification-user {
  font-weight: bold;
  color: #333;
}

.notification-action {
  font-weight: normal;
  color: #555;
}

.notification-target {
  font-weight: normal;
  color: #555;
}

.notification-timestamp {
  font-size: 0.75em;
  color: #999;
}

.unread {
  background-color: #edf2fa;
}

/* ... existing styles ... */

.notification-avatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.notification-initials {
  /* display: none; */
  /* Hidden by default */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  /* Placeholder color */
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  /* Centers the text vertically */
  margin-right: 10px;
}

/* ... existing styles ... */

/* Scrollbar Style */

/* width */
.notification-dropdown-menu::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.notification-dropdown-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.notification-dropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.notification-dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.notification-dropdown-menu::-webkit-scrollbar-button:single-button {
  display: none;
}
