/* .seller-dashboard-sidebar {
  min-width: 250px;
} */

.seller-dashboard-content {
  width: 100%;
}

/* service provider sidebar css */

.activesidebar {
  background-color: #FFCC00;
  color: #000034;
  font-weight: 600;
  font-size: 16px;
  margin: 2px;
  text-decoration: none;
  width: 192px;
  height: 50px;
  border-radius: 6px;
}

.notactivesidebar {
  color: #000034;
  font-weight: 600;
  font-size: 16px;
  margin: 2px;
  text-decoration: none;
}

.service-card-heads{
  font-size: 28px;
  font-weight: 600;
  color: #202224;
}

.service-card{
  background-color: white;
  box-shadow: 6px 6px 54px 0px #0000000D;
  border-radius: 14px;

}


.dashboard-card-content{
  color:'#202224';
  font-size: 16px;
  font-weight: 600;
  text-align: left;

}
.dashboard-card-figure{
  color: #202224;
  font-size: 28px;
  font-weight: 700;
  /* line-height: 38.19px; */
  letter-spacing: 1px;
  text-align: left;
}

.dashboard-card-p{
  font-size: 16px;
  font-weight: 600;
  /* line-height: 21.82px; */
  text-align: left;
}

.main-d-headings{
  font-size: 32px;
  font-weight: 700;
  color: #202224;
}

/* /////login sign up forget poassowrd input */
.login-input{
  background-color: #FAFCFF;
  height: 40px;
  width: 100%;
  border: 1px solid #D8D8D8;
}

.login-label{
  color: #202224;
  font-size: 14px;
  font-weight: 500;
}



/* form css */
.form-input{
  background-color: #FAFCFF;
  height: 40px;
  width: 100%;
  border: 1px solid #D8D8D8;
}

.custom-placeholder::placeholder {
  font-size: 14px;
  font-weight: 600;
  color: #20222480;
  font-family: 'Nunito Sans', sans-serif;
  padding-left: 16px;
}


.form-label{
  color: #202224;
  font-size: 16px;
  font-weight: 500;
}

.form-time-input{
  background-color: #FAFCFF;
  height: 40px;
  width: 100%;
  border: 1px solid #FFCC00;
  padding: 20px;
  border-radius: 8px;
}

.onhovereffect {
  position: relative;
  display: inline-block;
  z-index: 0;
}

.hide {
  display: none;
  position: absolute;
  top: 40%;
  left:20%;
  z-index: 70;
}

.onhovereffect:hover + .hide {
  display: block;
}

.imagehandling{
  position: relative;
}


.featurespan {
  background-color: #F1F4F9;
  border-radius: 6px;
  border: 1px solid #979797;
  width: 159px;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}

.featurespan.selected {
  background-color: #FFCC00; /* Yellow background for selected state */
}

.featurespan.selected:hover .remove-icon {
  display: block !important;
  position: absolute;
}

.featurespan.selected:hover span:first-child {
  visibility: hidden; /* Hide the tick icon when hovering */
}

.custom-modal-width{
  width: '150%';
}

.form-time-selections{
    border: 1px solid #ddd;
  }


  .profile-labels{
    font-size: 22px;
    font-weight: 600;
    color: #202224;
  }


  .modal-dialog {
    position: relative;
    display: table;
    overflow: auto;
    width: auto;
  }