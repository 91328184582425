.btn {
  align-items: center;
  appearance: none;
  border-radius: 4px;
  border-style: none;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0 2px 2px 0,
    rgba(0, 0, 0, 0.12) 0 1px 5px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  letter-spacing: 0.0892857em;
  line-height: normal;
  min-width: 64px;
  outline: none;
  overflow: visible;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  will-change: transform, opacity;
}
.btn_option:hover,
.btn:hover {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0 4px 5px 0,
    rgba(0, 0, 0, 0.12) 0 1px 10px 0;
}

btn_option:disabled,
.btn:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow:
    rgba(0, 0, 0, 0.2) 0 0 0 0,
    rgba(0, 0, 0, 0.14) 0 0 0 0,
    rgba(0, 0, 0, 0.12) 0 0 0 0;
  color: rgba(0, 0, 0, 0.37);
  cursor: default;
  pointer-events: none;
}
btn_option:not(:disabled),
.btn:not(:disabled) {
  background-color: #ffcc00;
}
btn_option:focus,
.btn:focus {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0 4px 5px 0,
    rgba(0, 0, 0, 0.12) 0 1px 10px 0;
}
btn_option:active,
.btn:active {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0 3px 14px 2px;
  background: #ffcc00;
}

.mineTextOrange {
  color: #ffcc00;
}

.blueTextColr {
  color: #000034;
}

.yellowtext {
  color: #ffcc00;
}

.myCursorClass {
  cursor: pointer;
}

.black-text {
  color: black !important;
}

.box-shadow {
  box-shadow: 0px 0px 8px 2px rgba(34, 34, 34, 0.74);
  -webkit-box-shadow: 0px 0px 8px 2px rgba(34, 34, 34, 0.74);
  -moz-box-shadow: 0px 0px 8px 2px rgba(34, 34, 34, 0.74);
}

/* body */
body {
  overflow-x: hidden;
}

/* purple #8704f5 */
/* green #17fa03 */

/* Home Card */
.left-box {
  border-bottom: 1px solid lightgray;
}
/* .showcase-card {
  overflow: hidden;
} */

.home-card {
  height: 410px !important;
}

.center-box {
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.right-box {
  border-bottom: 1px solid lightgray;
}

.card-boxes {
  background-color: rgb(252, 247, 247);
}

.selected-box {
  color: #ffcc00;
  border-top: 5px solid #ffcc00;
}

.card-boxes:hover {
  color: #ffcc00;
  cursor: pointer;
}

@media (max-width: 768px) {
  .home-card {
    width: 100% !important;
  }
}

/* Home hot offers */
.homeHotOfferList {
  list-style: none;
}

/* home top cards */

.orange-color {
  color: #ffcc00;
}
.orange-bg {
  background: #ffcc00;
}
.bg-orange {
  background-color: #ffcc00;
}

.bg-outline-orange {
  border: 2px solid #ffcc00 !important;
  background-color: #fff !important;
  color: black !important;
}
.bg-outline-orange:hover {
  background-color: #ffcc00 !important;
  color: black !important;
}

.bg-green {
  background-color: #17fa03;
}

.bg-gradient-1 {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffbf96),
    to(#ff7f50)
  );
  background: linear-gradient(90deg, #ffbf96, #ff7f50);
}

.bg-gradient-2 {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgb(245, 192, 128)),
    color-stop(99%, rgba(255, 140, 0, 1))
  );
  background: linear-gradient(
    90deg,
    rgb(245, 192, 128),
    rgba(255, 140, 0, 1) 99%
  );
}

.bg-gradient-3 {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffdab9),
    to(#e2934d)
  );
  background: linear-gradient(90deg, #ffdab9, #e2934d);
}

/* Home css */

/* .dark-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 80vh;
} */

/* #why-orange {
  background: rgb(255, 204, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 204, 0, 1) 0%,
    rgba(255, 140, 0, 1) 100%
  );
} */

/* #clients img {
  margin-top: -50px;
}

#clients .card:hover {
  background-color: #ffcc00;
  color: #fff;
} */

/* Search Package Details */

.top-image {
  width: 100%;
  height: 300px;
}

/* Navbar */
.mineTextOrange {
  color: #ffcc00;
}

.mineBackgroundTextOrange {
  background-color: #ffcc00;
  color: black;
}

/* Listing */

.border-listing-mine {
  border: 5px solid #ffcc00;
}

.listing-card-badge-background-mine {
  background-color: #ffcc00;
}

.listingCard {
  transition: transform 0.4s ease;
}

.listingCard:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

/* GEO API */
.geoapify-autocomplete-input {
  border: none;
}

/* Multi select */
.search-wrapper {
  all: unset;
}

.multiSelectContainer ul {
  border: none;
}
.optionListContainer {
  position: static;
}

/* Dashboard */

.fullHeight {
  min-height: 100vh;
}

.pointer {
  cursor: pointer;
}

.leaflet-container {
  height: 450px;
}

/* Media queries */

@media (max-width: 768px) {
  .authWidth {
    width: 90% !important;
  }
}

.authWidth {
  margin-top: 100px !important;
}

/* .border-bottom {
  border-bottom: 3px solid #333 !important;
  width: 30%;
  border-radius: 5px;
  margin: auto;
} */

.custom-dropdown-menu {
  background-color: white; /* Set the background color to white */
}

.custom-dropdown-menu .dropdown-item-content:hover {
  background-color: white; /* Set the background color to white on hover */
  color: black; /* Set the text color to black on hover */
}
.purple-hover:hover {
  background-color: #ffcc00;
  color: white; /* Change text color as needed */
  cursor: pointer; /* Add a pointer cursor on hover */
}
.nav-link:hover {
  color: #ffcc00;
}
.nav-link:active {
  color: #ffcc00;
}
.nav-link {
  font-weight: bold;
}
.active-link {
  color: #ff6600; /* Change this to the desired active link color */
}

.footer {
  background-color: #333; /* Footer background color */
  color: #fff; /* Footer text color */
  text-align: center; /* Center-align the content in the footer */
  position: relative; /* Position the footer */
}
#showcase-area {
  min-height: 80vh;
  background: url("https://res.cloudinary.com/dxqfv1xa2/image/upload/v1695505117/ublrgxs68xmpkv9pcc62.jpg")
    center no-repeat;
}

/* Add Tooltip Styles */
.react-tooltip {
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}
/* Add this to your CSS file */
.custom-confirm-button {
  background-color: #ffcc00 !important;
  color: black !important;
  border-color: #ffcc00 !important;
}

.pagination .page-item:hover .page-link {
  background-color: #ffcc00;
  border-color: #ffcc00;
  color: black !important;
}

.pagination .page-item.active .page-link {
  background-color: #ffcc00;
  border-color: #ffcc00;
  color: black !important;
}

.page-link {
  color: black !important; /* Use !important to override Bootstrap styles */
}

#some-cards{
  background-color: #fff !important;
}


.a-font {
  font-family: 'Archivo', sans-serif;
}

.n-font {
  font-family: 'Nunito Sans', sans-serif;
}
